import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import '$lib/firebase-client/app'
import { dev } from '$app/environment'

import { injectSpeedInsights } from '@vercel/speed-insights/sveltekit'
import clientConfig from '$lib/clientConfig'

import { user } from '$lib/stores/userStore'
import { get } from 'svelte/store'

if (clientConfig.useSentry) {
	Sentry.init({
		dsn: 'https://0fcd40455b3f630e991a733543061152@o4506808933285888.ingest.sentry.io/4506808935972864',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration()],
		debug: dev,
		environment: dev ? 'development' : 'production',
		beforeSend(event, hint) {
			const data = hint.data
			if (data && data.form) {
				// TODO Filter out sensitive form data
			}
			const userData = get(user)
			if (userData) {
				// TODO filter out sensitive user data
			}
			event.extra = { data, userData }
			return event
		}
	})
}

export const handleError = handleErrorWithSentry()

injectSpeedInsights({ debug: dev })
